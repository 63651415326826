import React from "react";
import "../../css/TheTeam.css";

class DonationPeople extends React.Component {

  render() {
    return (
      <section className="page-section bg-primary" style={{ height:'100vh' }} id="main">
      <div className="container px-4 px-lg-5 h-100">
          <div className="row gx-4 gx-lg-5 h-100 justify-content-center">
              <div className="col-lg-8 text-center">
                  <h2 className="text-white mt-0" style={{ fontFamily: "Ubuntu Condensed", color:'white', fontSize:'3em' }}>Supporters</h2>
                  <br/>
                  <p className="text-white-75 mb-4"></p>
              </div>
          </div>
      </div>
  </section>
    );
  }
}

export default DonationPeople;
