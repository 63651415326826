import * as React from "react";
import "../css/AppBar.css";

class NavBar extends React.Component {
  getScreen = (num) => {
    this.setState({ screen: num, loading: true });
    this.props.screen(num);
  };

  render() {
    return (
      <nav
        className="navbar navbar-expand-lg navbar-light fixed-top py-3"
        id="mainNav"
      >
        <div className="container px-4 px-lg-5">
          <a className="navbar-brand" href="#page-top">
            <img
              id="Logo"
              alt=""
              src="https://akotahi.s3-ap-southeast-2.amazonaws.com/images/logo_hq.png"
              style={{ margin: "0.5em", width: "4em", borderRadius: "2em", display:'inline' }}
            ></img>
            <h2 id="navbar-title" style={{ textAlign:'center', color:'white', fontFamily:"Ubuntu Condensed", textShadow: '0 0 8px gray, 2px 8px 10px black', display:'inline', marginLeft:'0.5em', fontSize:'1.7em' }}>AKO TAHI</h2>
          </a>
          <button
            className="navbar-toggler navbar-toggler-right"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ms-auto my-2 my-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#page-top"
                  onClick={() => this.getScreen(0)}
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#main"
                  onClick={() => this.getScreen(1)}
                >
                  About
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#main"
                  onClick={() => this.getScreen(2)}
                >
                  Supporters
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#main"
                  onClick={() => this.getScreen(3)}
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default NavBar;
