import React from "react";
import NavBar from "./components/NavBar";

import Main from "./components/Screens/Main";

import "./css/Styles.css";

class App extends React.Component {
  state = { loading: true, screen: 1 };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
  }

  getScreenNum = (num) => {
    this.setState({ screen: num });
  };

  render() {
    return (
      <div>
        {this.state.loading ? (
          <div>
            <div className="ui active dimmer">
              <div className="ui large text loader">Loading</div>
            </div>
            <p></p>
          </div>
        ) : (
          <div>
            <NavBar
              screen={(num) => {
                this.getScreenNum(num);
              }}
            />
            <Main screenSelect={this.state.screen} />
          </div>
        )}
      </div>
    );
  }
}

export default App;
