import React from 'react';


class Contact extends React.Component {

    render() {
        return (
            <section className="page-section bg-primary" style={{ height:'100vh' }} id="main">
            <div className="container px-4 px-lg-5 h-100">
                <div className="row gx-4 gx-lg-5 h-100 justify-content-center">
                    <div className="col-lg-8 text-center">
                        <h2 className="text-white mt-0" style={{ fontFamily: "Ubuntu Condensed", color:'white', fontSize:'3em' }}>Contact Us</h2>
                        <br/>
                        <p className="text-white-75 mb-4" style={{ fontFamily: "Ubuntu Condensed", color:'white', fontSize: "2em", textShadow:'0 0 5px gray, 0 0 7px black', padding:'3em' }}>
                            If you have any questions or would like to provide feedback, please contact us at the following email address: <a href="mailto:support@akotahi.nz" target="_blank" style={{ color:"rgba(10,10,190,0.8)", textShadow:'0 0 5px gray, 0 0 20px black' }}>support@akotahi.nz</a>
                        </p>
                    </div>
                </div>
            </div>
        </section>
        )        
    }
}

export default Contact;