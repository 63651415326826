import React from "react";

import DonationPeople from "./DonationPeople";
import Contact from "./Contact";

import banner from '../../assets/images/banner.png'

class Main extends React.Component {
  MainScreen = () => {
    if (this.props.screenSelect === 1) {
      return (
        <section
          className="page-section bg-primary"
          style={{ height: "100%" }}
          id="main"
        >
          <div className="container px-4 px-lg-5 h-100">
            <div className="row gx-4 gx-lg-5 h-100 justify-content-center">
              <div className="col-lg-8 text-center">
                <img src={banner} style={{ borderRadius:'40px', width:'100%', height:'auto' }}></img>
                <hr style={{ color: "white" }} />
                <p
                  style={{ fontFamily: "Ubuntu Condensed", color:'white', fontSize: "2em", textShadow:'0 0 8px gray, 2px 8px 10px black' }}
                  className="text-white-75 mb-4"
                >
                  Nau Mai, Haere Mai (Welcome)
                  <br />
                  <br />
                  Ako Tahi means "Learn Together". It is a (free) Te Reo Māori phrases learning app that contains 1300+ phrases. It has been designed to help people learn the most commonly used Te Reo Māori phrases. Ako Tahi re-enforces learning through touch, vision, listening, speaking, texting and by challenging yourself and others.
                  <br/>This app was developed as a "grassroots" side project 4 years ago. It was created by a self-taught Software Developer with the help from his aunt (who assisted with the Te Reo Māori translations).
                </p>
              </div>
            </div>
          </div>
          <h2 style={{ textAlign:'center', color:'white', fontSize:"2em", fontFamily:"Ubuntu Condensed", textShadow: '0 0 8px gray, 2px 8px 10px black' }}><span>©</span> AKO TAHI LIMITED 2022<span>™</span></h2>
        </section>
      );
    }
    if (this.props.screenSelect === 2) {
      return <DonationPeople />;
    }
    if (this.props.screenSelect === 3) {
      return <Contact />;
    }
  };

  render() {
    return <div>{this.MainScreen()}</div>;
  }
}

export default Main;
